import api from '@/apis/api'
/**
 * 自付账单相关api
 */
export class selfLabelBillApi extends api {

    getList (condition) {
        return this.request({
            url: `${this.servicePath}/${this.modulePath}/labelBillList`,
            method: 'post',
            data: condition
        })
    }

    getManufacturers(){
        return this.request({
            url: `${this.servicePath}/${this.modulePath}/getManufacturers`,
            method: 'get'
        })
    }

    exportLabelBills(condition) {
        return this.request({
            url: `${this.servicePath}/${this.modulePath}/exportAll`,
            method: 'post',
            responseType: 'blob',
            data: condition
        })
    }

    exportAllLabelBill(condition) {
        return this.request({
            url: `${this.servicePath}/${this.modulePath}/allExport`,
            method: 'post',
            responseType: 'blob',
            data: condition
        })
    }
}

export default new selfLabelBillApi('self')
