<template>
  <list-condition-template ref="table" :rows="rows" :total="total"
                           v-model:list-query="listQuery" :multiple-selected="true" :footer-show="false" @selected="selectedBills">
    <template #condition>
      <el-form-item label="账单时间" required>
        <el-date-picker
            v-model="listQuery.condition.date"
            type="month"
            :disabled-date="disabledDate"
            placeholder="选择月"
            format="YYYY 年 MM 月"
            value-format="YYYY-MM-DD"
            :clearable="false"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="ODM制造商">
        <el-select v-model="listQuery.condition.accountId" placeholder="请选择" clearable filterable>
          <el-option v-for="item in manufacturerList" :key="item.accountId" :label="item.name" :value="item.accountId"/>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleQuery">搜索</el-button>
        <el-button type="primary" icon="el-icon-download" @click="exportLabelBill">导出标签账单</el-button>
        <el-button type="primary" icon="el-icon-download" @click="exportAllLabelBill">导出全部标签账单</el-button>
      </el-form-item>
    </template>
    <template #columns>
      <el-table-column label="账单周期" align="center">
        <template #default="scope">
          {{scope.row.year + '年' + scope.row.month + '月'}}
        </template>
      </el-table-column>
      <el-table-column label="ODM制造商" align="center" prop="manufacturer.name"/>
      <el-table-column label="账单类型" align="center" prop="payType.type"/>
      <el-table-column label="厨电标签(套)" align="center" prop="kitchenLabelCount"/>
      <el-table-column label="小电标签(套)" align="center" prop="homeLabelCount"/>
      <el-table-column label="合计(元)" align="center" prop="labelTotalPrice"/>
      <el-table-column label="操作" align="center">
        <template #default="scope">
          <el-button type="text" @click="handleView(scope.row)">查看详情</el-button>
        </template>
      </el-table-column>
    </template>
  </list-condition-template>
</template>

<script>
import ListConditionTemplate from "@/components/templates/listConditionTemplate";
import selfLabelBillApi from "@/apis/bill/labelBill/self";
export default {
  components: {ListConditionTemplate},
  data() {
    return {
      total: 0,
      rows: [],
      listQuery: {
        condition: {
          payType: 'SELF',
          accountId: '',
          labelBillIds: [],
          selectIds: [],
          date: ''
        },
        pageInfo: {
          pageIndex: 1,
          pageSize: 20,
          orders: []
        }
      },
      manufacturerList: [],
      selectedBillList: [],
    };
  },
  created() {
    // 设置默认账单时间 最近一个月
    this.initDefaultDate()
    // 获取全部odm制造商
    this.getManufacturerAll()
  },
  methods: {
    disabledDate(time) {
      const nowDate = new Date();
      nowDate.setMonth(nowDate.getMonth() - 1)
      return time.getTime() > nowDate.getTime();
    },
    initDefaultDate() {
      const nowDate = new Date();
      this.listQuery.condition.date = nowDate.getFullYear() + '-'
          + ((nowDate.getMonth()) >= 10 ? (nowDate.getMonth()) : '0' + (nowDate.getMonth())) + '-01';
    },
    getManufacturerAll() {
      // 获取制造商列表
      selfLabelBillApi.getManufacturers().then(res => {
        this.manufacturerList = res.data
      })
    },
    getList() {
      selfLabelBillApi.getList(this.listQuery.condition).then(res => {
        this.rows = res.data
      })
    },
    handleQuery() {
      console.log(this.listQuery.condition)
      this.getList()
    },
    selectedBills(data) {
      this.listQuery.condition.selectIds = data?.map(item => {
        return item.manufacturer.id
      })
      this.listQuery.condition.labelBillIds = data?.map(item => {
        return item.id
      })
    },
    exportLabelBill() {
      if (this.listQuery.condition.selectIds.length <= 0) {
        this.$message.warning('请选择导出账单')
        return
      }
      selfLabelBillApi.exportLabelBills(this.listQuery.condition).then(res => {
        const zipName = this.listQuery.condition.date.substring(0,4) + '年' + this.listQuery.condition.date.substring(5,7) + '月' + '自付标签账单.zip'
        saveAs(res.data,zipName)
      }).catch(res => {
        this.$message.error("无法下载")
      })
    },
    exportAllLabelBill() {
      this.$confirm(
          '是否导出' + this.listQuery.condition.date + '所有自付数据？', '提示',
          {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
          }
      ).then(() => {
        selfLabelBillApi.getList(this.listQuery.condition).then(res => {
          if (res?.data?.length> 0) {
            const manufacturerIds = res.data.map(item => item.manufacturer.id)
            const labelBillIds = res.data.map(item => item.id)
            const param = JSON.parse(JSON.stringify(this.listQuery.condition))
            param.selectIds = manufacturerIds
            param.labelBillIds = labelBillIds
            selfLabelBillApi.exportAllLabelBill(param).then(res => {
              const exName = this.listQuery.condition.date.substring(0,4) + '年' + this.listQuery.condition.date.substring(5,7) + '月' + '所有自付标签账单.xlsx'
              saveAs(res.data,exName)
            }).catch(res => {
              this.$message.error("无法下载")
            })
          }
        })
      }).catch(() => {
        this.$message.warning("取消下载")
      })
    },
    handleView(row) {
      const data = encodeURIComponent(JSON.stringify(row))
      this.$router.push({
        path: '/bill/labelBill/info',
        query: {
          data: data
        }
      })
    },
  }
}
</script>

<style scoped>

</style>
